import React from "react";
import { graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby-plugin-modal-routing";
import CloseButton from "../components/Navbar/Cross"


const NavItem = styled.div`
  padding: 2em 0;
  display: flex;
  flex-direction: column;
`

const Leave = styled(Link)`
  position: sticky;
  top: 1em;
  font-weight: 900;
  font-size: 1.5em;
  text-decoration: none;
  align-self: flex-end;
`


class PolicyTemplate extends React.Component {
 
  
  
  render() {
    const item = this.props.data.markdownRemark
    
    return (
        
        <NavItem>
          <Leave to="/" state={{noScroll: true}}><CloseButton /></Leave>
          <h2>{item.frontmatter.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
        </NavItem>
    )    
  }
}  

export default PolicyTemplate

export const pageQuery = graphql`
  query ItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
    }
  }
`


import React from "react";
import styled from "styled-components"

const Cross = styled.div`
background-color: #111;
width: 30px;
height: 3px;
align-self: center;
position: relative;
transform: rotate(-45deg) translateY(-20px);

::after {
 width: 30px;
 height: 3px;
 background-color: #111;
 content: "";
 position: absolute;
 transform: rotate(-90deg) translate(-10px, 0px);
 top: -10px;
}
`

const CloseButton = () => {
    return (
        <Cross />
    )
}

export default CloseButton